<template>
  <div>
    <template v-if="isEventTypeNew">
      <v-icon
        color="primary"
        style="height:30px;width:30px"
        :style="{ 'background-image': 'url(' + require('@/assets/icons/' + type + '.svg') + ')' }"
      ></v-icon>
    </template>
    <div v-else-if="showTooltip">
      <v-tooltip top v-if="type">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" :color="color" :size="size" v-if="category === 'sensor'">
            {{ sensors[type] }}
          </v-icon>
          <v-icon v-on="on" :color="color" :size="size" v-else>
            {{ eventTypes[type] }}
          </v-icon>
        </template>
        <span class="font-weight-bold" v-if="category === 'sensor'">{{ $t("sensors." + type) }}</span>
        <span class="font-weight-bold" v-else>{{ $t("event_types." + type) }}</span>
      </v-tooltip>
      <div v-else>-</div>
    </div>
    <div v-else>
      <template v-if="type">
        <v-icon :color="color" :size="size" v-if="category === 'sensor'">
          {{ sensors[type] }}
        </v-icon>
        <div v-if="category === 'sensor'">{{ $t("sensors." + type) }}</div>
        <v-icon :color="color" :size="size" v-else>
          {{ eventTypes[type] }}
        </v-icon>
        <div v-if="category === 'event'">{{ $t("event_types." + type) }}</div>
      </template>

      <div v-else>-</div>
    </div>
  </div>
</template>

<script>
  import { sensors, eventTypes } from "@/base/data";

  export default {
    name: "IdentityTypeIcon",
    components: {
      HOME_CLOCK_IN: () => import("@/assets/icons/HOME_CLOCK_IN.svg"),
      HOME_CLOCK_OUT: () => import("@/assets/icons/HOME_CLOCK_OUT.svg"),
      TRAINING_IN: () => import("@/assets/icons/TRAINING_IN.svg"),
      TRAINING_OUT: () => import("@/assets/icons/TRAINING_OUT.svg")
    },
    props: {
      type: {
        required: true
      },
      color: {
        required: false,
        default: "grey"
      },
      size: {
        required: false,
        default: "30"
      },
      showTooltip: {
        type: Boolean,
        required: false,
        default: true
      },
      category: {
        type: String,
        required: false,
        default: "sensor"
      }
    },
    data: vm => ({
      sensors,
      eventTypes
    }),
    computed: {
      isEventTypeNew() {
        return ["HOME_CLOCK_IN", "HOME_CLOCK_OUT", "TRAINING_IN", "TRAINING_OUT"].includes(this.type);
      }
    }
  };
</script>

<style scoped></style>
